import "../sentry";
import React, { lazy, Suspense, useEffect, useState } from "react";
import useRedirectUrl from "@common/hooks/useRedirectUrl";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import Loader from "./common/components/Loader";
import PrivateRoute from "./common/components/PrivateRoute";
import ReportIssue from "./common/components/ReportIssue";
import ScrollToTop from "./common/components/ScrollToTop";
import ToastNotification from "./common/components/ToastNotification";
import { BreakpointProvider } from "./common/hooks/Breakpoint";
import { GauzeContextProvider } from "./pages/Gauze/GauzeContext";
import routes from "./routes";
import UserService from "./services/user.service";
import useScriptsHooks from "@common/hooks/ScriptsHooks";
const Auth = lazy(() => import("./pages/Auth"));

const Header = lazy(() => import("./common/components/Header"));

const Fallback = styled.div`
    height: 100%;
    background: var(--bg-color);
`;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
`;

// const ComponentWrapper = styled.div`
//     overflow: auto;
//     // border:2px solid red;
//     margin-top: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
//         isUserLoggedIn && showTopNavbar ? (showBanner ? `${bannerHeight + 64}px` : "64px") : "0px"};
//     height: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
//         isUserLoggedIn && showTopNavbar
//             ? showBanner
//                 ? `calc(100% - ${bannerHeight + 64}px)`
//                 : "calc(100% - 64px)"
//             : "100%"};
// `;

// const ComponentWrapper = styled.div`
//     overflow: auto;
//     margin-top: ${({ isUserLoggedIn, showTopNavbar }) =>
//         isUserLoggedIn && showTopNavbar ? "56px" : "0px"};
//     height: ${({ isUserLoggedIn, showTopNavbar }) =>
//         isUserLoggedIn && showTopNavbar ? `calc(100% - 56px)` : "100%"};
// `;

const ComponentWrapper = styled.div`
    overflow: auto;
    margin-top: ${({ isUserLoggedIn, showTopNavbar, isHideNavbar }) =>
        isUserLoggedIn && showTopNavbar && !isHideNavbar ? "56px" : "0px"};
    height: ${({ isUserLoggedIn, showTopNavbar, isHideNavbar }) =>
        isUserLoggedIn && showTopNavbar && !isHideNavbar ? `calc(100% - 56px)` : "100%"};
`;

const ModalRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1003;
`;

const App = () => {
    useScriptsHooks();
    let appRoutes = routes;

    let [newRoutes, setNewRoutes] = useState(routes);

    useRedirectUrl();
    // const scrollTopVisible = useSelector((state) => state.uploadDetails.scrollTopVisible);
    const scrollTopVisible = false;
    const showTopNavbar = useSelector((state) => state.menuDetails.showTopNavbar);
    const isLoaderOverlayVisible = useSelector((state) => state.menuDetails.isLoaderOverlayVisible);
    const { isUserLoggedIn } = useSelector((state) => state.authenticationDetails);
    const { showBanner } = useSelector((state) => state.menuDetails.showBanner);
    const bannerHeight = useSelector((state) => state.menuDetails.bannerHeight);

    const location = useLocation();

    const isHideNavbar = location.pathname.includes("workspace");

    console.log("isHideNavbar:", isHideNavbar);

    useEffect(() => {
        UserService.getCurrentUserSession()
            .then((response) => {
                const message = {
                    type: "sessionData",
                    data: response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            })
            .catch((error) => {
                // Handle error
                const message = {
                    type: "sessionData",
                    data: error.response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            });
    }, []);

    return (
        <GauzeContextProvider>
            <Wrapper data-testid="app">
                {/* <BrowserRouter> */}
                <BreakpointProvider>
                    <ThemeProvider theme={{ scrollTopVisible }}>
                        <ScrollToTop />
                        {!isHideNavbar && (
                            <Suspense fallback={<Fallback />}>
                                <Header />
                            </Suspense>
                        )}
                        <ComponentWrapper
                            id="componentWrapper"
                            isUserLoggedIn={isUserLoggedIn}
                            showTopNavbar={showTopNavbar}
                            isHideNavbar={isHideNavbar}

                            // showBanner={showBanner}
                            // bannerHeight={bannerHeight}
                        >
                            <Suspense fallback={<Fallback />}>
                                <Routes>
                                    {routes.map((route, index) => (
                                        <Route
                                            path={`${route.path}`}
                                            key={index}
                                            element={
                                                <>
                                                    <PrivateRoute
                                                        component={route.component}
                                                        guard={route.guard}
                                                        path={route.path}
                                                    />
                                                </>
                                            }
                                        />
                                    ))}
                                </Routes>
                            </Suspense>
                        </ComponentWrapper>
                    </ThemeProvider>
                </BreakpointProvider>
                {/* <ReportIssue /> */}
                {/* </BrowserRouter> */}
                <ToastNotification />
                {isLoaderOverlayVisible && <Loader />}
                <ModalRoot id="modal-root" />
                {/* <SheetRoot id="sheet-root" />
                <CropRoot id="crop-root" />
                <JavascriptEditor id="javascript-editor" /> */}
            </Wrapper>
        </GauzeContextProvider>
    );
};

// export default hot(module)(App);
export default App;
