import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import store from "./common/redux/store";
import { GlobalStyle } from "./GlobalStyle";
import App from "./App";
import { Provider } from "react-redux";
import "./common/events";
import { AppProvider } from "./AppContext";
import { ThemeProvider, NotificationProvider } from "fds-web";
import { BrowserRouter } from "react-router-dom";
import { EchoPreviewProvider } from "./pages/EchoPreviewContext/index";

// ReactDOM.render(
//     <Provider store={store}>
//         <AppProvider>
//             <React.StrictMode>
//                 <GlobalStyle />
//                 <App />
//             </React.StrictMode>
//         </AppProvider>
//     </Provider>,
//     document.getElementById("root"),
// );

const themeObject = {
    font: {
        fontFamily: "",
    },
    spacing: {
        "dimensions-28": "",
    },
    breakpoints: {},
    color: [
        {
            name: "theme 1",
            value: {
                light: {
                    "fds-primary-10": "#EFFFFD",
                    "fds-primary-20": "#E0F3F0",
                    "fds-primary-30": "#A1E6DB",
                    "fds-primary-40": "#56C6B4",
                    "fds-primary-50": "#12B59B",
                    "fds-primary-60": "#097B69",
                    "fds-primary-70": "#0B564A",
                    "fds-primary-80": "#041D19",
                },
            },
        },
    ],
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <NotificationProvider autoClose duration={2000} position="bottom-right">
                <EchoPreviewProvider>
                    <AppProvider>
                        {/* <React.StrictMode> */}
                        <ThemeProvider theme={themeObject} />
                        <GlobalStyle />
                        <App />
                        {/* </React.StrictMode> */}
                    </AppProvider>
                </EchoPreviewProvider>
            </NotificationProvider>
        </BrowserRouter>
    </Provider>,
);
